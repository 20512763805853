/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import CctomeList from '@/components/bpm/MyProcess/CctomeList';
import solBox from '@/components/common/solBox';
export default {
  name: "my-cctome",
  data() {
    return {
      showFilter: false,
      solData: [],
      allSel: false,
      searchVal: ""
    };
  },
  components: {
    CctomeList,
    solBox
  },
  created() {
    this.loadSolData();
    this.loadParam();
  },
  methods: {
    loadParam() {
      this.searchVal = this.$store.state.searchModule.instSearchVal;
    },
    openPopStop(event) {
      event.stopPropagation();
    },
    loadSolData() {
      var url = _baseUrl + "/bpm/core/bpmSolution/getCatTree.do?isAdmin=false";
      this.$ajax.get(url).then(response => {
        this.solData = response.data;
      });
    },
    openPop() {
      this.showFilter = !this.showFilter;
      var queryTree = this.$store.state.searchModule.instSearchTreeIds;
      var sols = this.$refs.solSelecteds;
      if (!queryTree || !sols) return;
      queryTree.split(",").forEach(function (treeid) {
        sols.forEach(function (sol) {
          if (sol.solPar.id == treeid) sol.isChoose = true;
        });
      });
    },
    reset() {
      this.allSel = false;
      this.clearSolSel();
      this.clear();
      this.$store.commit('setInstSearchTreeIds', "");
      this.showFilter = false;
    },
    all() {
      this.allSel = !this.allSel;
      this.clearSolSel();
    },
    ok() {
      var treeIds = [];
      let sels = this.$refs.solSelecteds || [];
      for (let i = 0, j = sels.length; i < j; i++) {
        let sel = sels[i];
        if (sel.isChoose) {
          treeIds.push(sel.solPar.id);
        }
      }
      this.$store.commit('setInstSearchTreeIds', treeIds.toString());
      this.showFilter = false;
    },
    search() {
      this.$store.commit('setInstSearchVal', this.searchVal);
    },
    clear() {
      this.searchVal = "";
      this.$store.commit('setInstSearchVal', this.searchVal);
    },
    clearSolSel() {
      let sels = this.$refs.solSelecteds;
      if (!sels) return;
      for (let i = 0, j = sels.length; i < j; i++) {
        sels[i].isChoose = false;
      }
    }
  }
};